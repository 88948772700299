import React, { lazy, Suspense } from 'react';
import {
    BrowserRouter, Route, Switch
} from 'react-router-dom';

import RootView from './page/root-view';
import PageHome from './page/home';
import RootViewStore from './page/root-view-store';

// chat界面
const PageChat = lazy(() => import('./page/chat'));

const PageActivity = lazy(() => import('./page/activity'));
const VerifyHome = lazy(() => import('./page/verify'));
const Page404NotFound = lazy(() => import('./page/404'));
// 商城
const StoreGoodsDetail = lazy(() => import('./page/store/goods-detail'));
const StoreSubmitOrder = lazy(() => import('./page/store/submit-order'));
const StoreOrderList = lazy(() => import('./page/store/order-list'));
const StoreOrderDetail = lazy(() => import('./page/store/order-detail'));
const StorePayResult = lazy(() => import('./page/store/pay-result'));

// 平行报价
const OrderList = lazy(() => import('./page/order/list'));
const OrderDetail = lazy(() => import('./page/order/detail'));
const QuoteDetail = lazy(() => import('./page/order/quote'));
const QuoteDetail2 = lazy(() => import('./page/order/quote2'));
const OrderHistory = lazy(() => import('./page/order/history'));
const OrderShare = lazy(() => import('./page/order/share'));
const OrderUpload = lazy(() => import('./page/order/upload'));
const OrderTools = lazy(() => import('./page/order/tools'));
const SpecialAppoint = lazy(() => import('./page/order/special-appoint'));
const AddSpecialAppoint = lazy(() => import('./page/order/add-special-appoint'));
const ContrastEnterPlate = lazy(() => import('./page/contrast/enter-plate'));
const ModelSelection = lazy(() => import('./page/contrast/model-selection'));
const InsuranceType = lazy(() => import('./page/contrast/insurance-type'));
const ChooseCompany = lazy(() => import('./page/contrast/choose-company'));

// 优享劵
const CouponList = lazy(() => import('./page/coupon/list'));
const CouponDetail = lazy(() => import('./page/coupon/detail'));
const CouponOverdue = lazy(() => import('./page/coupon/overdue'));
const CouponDetailView = lazy(() => import('./page/coupon/detail-view'));



function RouterConfig () {
    return (
        <BrowserRouter>
            <Suspense fallback={<div>Loading...</div>}>

                <Switch>
                    <Route exact path="/" render={() => (<RootView path="/" title="用户中心" Component={PageHome} />)} />

                    <Route exact path="/chat" render={() => (<RootView path="/chat" title="智能客服" Component={PageChat} />)} />
                    <Route exact path="/activity" render={() => (<RootView path="/activity" title="活动" Component={PageActivity} />)} />

                    <Route exact path="/renzheng" render={() => (<RootView path="/renzheng" title="首页" Component={VerifyHome} />)} />
                    <Route exact path="/order/list" render={() => (<RootView path="/order/list" title="订单列表" Component={OrderList} />)} />
                    <Route exact path="/order/detail" render={() => (<RootView path="/order/detail" title="订单详情" Component={OrderDetail} />)} />
                    <Route exact path="/order/quote" render={() => (<RootView path="/order/quote" title="报价单详情" Component={QuoteDetail} />)} />
                    <Route exact path="/order/quote2" render={() => (<RootView path="/order/quote2" title="报价单详情" Component={QuoteDetail2} />)} />
                    <Route exact path="/order/history" render={() => (<RootView path="/order/history" title="历史报价" Component={OrderHistory} />)} />
                    <Route exact path="/order/share" render={() => (<RootView path="/order/share" title="分享" Component={OrderShare} />)} />
                    <Route exact path="/order/upload" render={() => (<RootView path="/order/upload" title="上传资料" Component={OrderUpload} />)} />
                    <Route exact path="/order/tools" render={() => (<RootView path="/order/tools" title="二手车保险报价" Component={OrderTools} />)} />
                    <Route exact path="/order/special-appoint" render={() => (<RootView path="/order/special-appoint" title="特约信息" Component={SpecialAppoint} />)} />
                    <Route exact path="/order/add-special-appoint" render={() => (<RootView path="/order/add-special-appoint" title="添加特约信息" Component={AddSpecialAppoint} />)} />

                    <Route exact path="/store/goods-detail" render={() => (<RootViewStore path="/store/goods-detail" title="商城" Component={StoreGoodsDetail} />)} />
                    <Route exact path="/store/submit-order" render={() => (<RootViewStore path="/store/submit-order" title="商城" Component={StoreSubmitOrder} />)} />
                    <Route exact path="/store/order-list" render={() => (<RootViewStore path="/store/order-list" title="商城" Component={StoreOrderList} />)} />
                    <Route exact path="/store/order-detail" render={() => (<RootViewStore path="/store/order-detail" title="商城" Component={StoreOrderDetail} />)} />
                    <Route exact path="/store/pay-result" render={() => (<RootViewStore path="/store/pay-result" title="商城" Component={StorePayResult} />)} />
                    <Route exact path="/contrast/enter-plate" render={() => (<RootViewStore path="/contrast/enter-plate" title="优Bao加" Component={ContrastEnterPlate} />)} />
                    <Route exact path="/contrast/model-selection" render={() => (<RootViewStore path="/contrast/model-selection" title="优Bao加" Component={ModelSelection} />)} />
                    <Route exact path="/contrast/insurance-type" render={() => (<RootViewStore path="/contrast/insurance-type" title="优Bao加" Component={InsuranceType} />)} />
                    <Route exact path="/contrast/choose-company" render={() => (<RootViewStore path="/contrast/choose-company" title="优Bao加" Component={ChooseCompany} />)} />

                    <Route exact path="/coupon/list" render={() => (<RootViewStore path="/coupon/list" title="我的卡劵" Component={CouponList} />)} />
                    <Route exact path="/coupon/detail" render={() => (<RootViewStore path="/coupon/detail" title="卡劵详情" Component={CouponDetail} />)} />
                    <Route exact path="/coupon/overdue" render={() => (<RootViewStore path="/coupon/overdue" title="历史卡劵" Component={CouponOverdue} />)} />
                    <Route exact path="/coupon/detail/view" render={() => (<RootViewStore path="/coupon/detail/view" title="优享券订单详情" Component={CouponDetailView} />)} />
                    <Route render={() => (<RootView path="/404" Component={Page404NotFound} />)} />
                </Switch>
            </Suspense>
        </BrowserRouter>
    );
}

export default RouterConfig;
