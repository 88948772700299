import React from 'react';
import './root-view.less';
// import Header from './components/header';

function Template({ Component }) {
  return ([
    // <Header />,
    <Component />
  ]);
}

export default Template;
