/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { GET } from '../../utils/request';
import { queryParse } from '../../utils/index';



import './index.less'

function HomePage() {
    const [isAudit, setIsAudit] = useState(false);

    useEffect(() => {
        GET('/api/@store/wxAuditStatus/statusQuery').then(res => {
            setIsAudit(res);
        });
    }, []);

    const toRedirect = () => {
        const reUrl = decodeURIComponent(window.AppData.redirectUrl).replace(/amp;/g, '')
        const search = reUrl.split('?')[1]
        const obj = queryParse(search)
        console.log(obj.channelNo);

        if (!obj.channelNo || obj.channelNo === 'undefined') {
            window.wx.miniProgram.redirectTo({
                url: '/pages/login/index',
            });
            return
        }

        if (window.AppData && window.AppData.redirectUrl) {
            const url = window.AppData.redirectUrl;

            if (url.startsWith('/pages')) {
                window.wx.miniProgram.navigateTo({
                    url: '/pages/mid-web',
                });
            } else {
                window.location.replace(url.replace(/amp;/g, ''));
            }
        }
    };

    const toIpc = () => {
        window.location.href = 'https://beian.miit.gov.cn';
    };

    return <div className="runchang-container">
        <img className="img" src="https://huiyuan-1312091486.cos.ap-beijing.myqcloud.com/%E6%9C%AA%E5%91%BD%E5%90%8D__2022-12-08%2B22_55_09.png" alt="" />
        {!isAudit ? <div className="button" onClick={toRedirect}>点击查看详情</div> : ''}
        <div className="beian" onClick={toIpc}>京ICP备2022015750号-1</div>
    </div>;
}

export default withRouter(HomePage);

