import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './root-view.less';

function Template({ Component, title }) {
    useEffect(() => {
        document.title = title;
    }, []);

    return (
        <Component />
    );
}

export default withRouter(Template);
