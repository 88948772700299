import { Toast } from 'antd-mobile'
import querystring from 'querystring';
import { getCookie, search } from './index'
import * as $$ from './index';

function requestGlobalConfig(url, options = {}) {
    // 这里可以做一些全局的ajax请求配置
    const newUrl = $$.addUrlQuery(url, {});

    /** access_token: eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJEYUJhaUF1dGhXaXRoSldUIiwic2Vzc2lvbktleSI6InIzZDNLSEVxNGdxeUdJSmNXRHFOQ3c9PSIsIm9wZW5JZCI6Im80b0c0NG1BYmhCVE91dGFxd0g2TWdpR2dPcHcifQ.ADhxop4iZ4vbCR5gsMpzDtwbmr3ZsoLvqVzpmpsMaAQ */
    if ((!options.headers || !options.headers.access_token)) {
        const query = search();
        const token = query.token || getCookie('token');
        // const token = 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhdWQiOiJEYUJhaUF1dGhXaXRoSldUIiwic2Vzc2lvbktleSI6InNQRWtDKytQR1AyTUEzLzJkWVh6ZlE9PSIsIm9wZW5JZCI6Im80b0c0NG5ucWFZM0tXVm9fNlU4bXZMcldreVkiLCJsb2NhbE9wZW5JZCI6Im80b0c0NHNfOFNPSzNkNHc5ZFVnRk1XbGZ4d0EifQ.M3kbG-O8HS6YkRx6EjjAsVpMOdRD78MIV_3VJ_z2WlE'
        if (token) {
            if (!options.headers) {
                options.headers = {};
            }
            options.headers.access_token = token;
        }
    }
    // 全局ajax添加头
    const newOptions = options;
    newOptions.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-csrf-token': $$.getCookie('csrfToken'),

        ...options.headers,
    };
    // 为了保证ajax中的Set-Cookie可以正常工作
    newOptions.credentials = 'same-origin';

    return {
        url: newUrl,
        options: newOptions,
    };
}

function createBodyString(data, type) {
    let body;
    switch (type) {
        case 'application/x-www-form-urlencoded':
            body = typeof data === 'object' ? querystring.stringify(data) : data;
            break;
        case 'application/json':
            body = typeof data === 'string' ? data : JSON.stringify(data);
            break;
        default:
            body = typeof data === 'string' ? data : JSON.stringify(data);
            break;
    }
    return body;
}

function parseJSON(response) {
    if (!response.redirected) {
        return response.json();
    }
    return response;
}

function checkStatus(response) {
    if (response.status >= 200 && response.status < 300) {
        return response;
    }

    const error = new Error(response.statusText);
    error.response = response;
    throw error;
}

/**
 * Requests a URL, returning a promise.
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 * @return {object}           An object containing either "data" or "err"
 */
function request(url, options) {
    const globalConfig = requestGlobalConfig(url, options);
    return fetch(globalConfig.url, globalConfig.options)
        .then(checkStatus)
        .then(parseJSON)
        .then(d => {
            if (!d.success && !d.data && (d.msg || d.errMsg || d.message)) {
                // message.error(d.msg || d.errMsg || d.message || '服务器错误', 5);
            }
            return d;
        })
        .catch(err => {
            let shouldToastErrorMessage = true;
            if (err.response && err.response.status === 404) {
                // 404不再弹出错误提示
                shouldToastErrorMessage = false;
            }
            if (shouldToastErrorMessage) {
                Toast.show({
                    icon: 'fail',
                    content: err.message || '服务器错误',
                })
            }
            if (window.loading) {
                window.loading.hide();
            }
            return { err };
        });
}

async function GET(url, query = {}, options) {
    let urlWithQuery = $$.addUrlQuery(url, query);

    urlWithQuery = await request(urlWithQuery, {
        method: 'GET',
        ...options,
    });
    return urlWithQuery;
}

async function DELETE(url, query = {}, options) {
    let urlWithQuery = $$.addUrlQuery(url, query);

    urlWithQuery = await request(urlWithQuery, {
        method: 'DELETE',
        ...options,
    });
    return urlWithQuery;
}

async function POST(url, data = {}, options = {}) {
    const urlWithQuery = await request(url, {
        method: 'POST',
        body: createBodyString(data, options.headers && options.headers['Content-Type']),
        ...options,
    });

    return urlWithQuery;
}

async function PATCH(url, data = {}, options = {}) {
    const urlWithQuery = await request(url, {
        method: 'PATCH',
        body: createBodyString(data, options.headers && options.headers['Content-Type']),
        ...options,
    });

    return urlWithQuery;
}

async function PUT(url, data = {}, options = {}) {
    const urlWithQuery = await request(url, {
        method: 'PUT',
        body: createBodyString(data, options.headers && options.headers['Content-Type']),
        ...options,
    });

    return urlWithQuery;
}

export {
    request,
    GET,
    POST,
    DELETE,
    PATCH,
    PUT,
};
