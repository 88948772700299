import urlUtil from 'url';
import querystring from 'querystring';
import _ from 'lodash';

function addUrlQuery(url, query) {
    const urlObject = urlUtil.parse(url, true);
    let newQuery = query;
    if (typeof newQuery === 'string') {
        if (newQuery.indexOf('?') === 0) {
            newQuery = newQuery.slice(1);
        }
        newQuery = querystring.parse(newQuery);
    }
    urlObject.query = { ...urlObject.query, ...newQuery };
    urlObject.search = null; // 必须将search设置为空，format才会处理query
    return urlUtil.format(urlObject);
}

function getCookie(name) {
    let cookieValue = null;
    if (document.cookie && document.cookie !== '') {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i += 1) {
            const cookie = String.prototype.trim.apply(cookies[i]);
            if (cookie.substring(0, name.length + 1) === (`${name}=`)) {
                cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                break;
            }
        }
    }
    return cookieValue;
}

function setCookie(name, value, expireDays = 0, cookiePath = '/') {
    const date = new Date();
    date.setTime(date.getTime() + (expireDays * 24 * 60 * 60 * 1000));
    document.cookie = `${name}=${value}; expires=${date.toGMTString()}; path=${cookiePath}`;
}

function clearCookie(name, cookiePath = '/') {
    setCookie(name, '', -1, cookiePath);
}


function fixNum(num) {
    return Math.round(num * 100) / 100;
}



function isEmpty(value) {
    return [undefined, null, ''].includes(value);
}

// 生成uuid
function generateId() {
    let d = +new Date();
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
        const r = ((d + Math.random() * 16) % 16) | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
    return uuid;
}


// 解析 query 参数
const queryParse = (queryString = '') => {
    let query;
    if (queryString.startsWith('?')) {
        query = queryString.substring(1);
    } else {
        query = queryString;
    }
    if (query === '') {
        return {};
    }
    return query.split('&').reduce((prev, cur) => {
        const [k, v = null] = cur.split('=');
        const val = _.isNull(v) ? v : decodeURIComponent(v);
        if (_.isUndefined(prev[k])) {
            prev[k] = val;
        } else {
            prev[k] = _.flatten([prev[k], val]);
        }
        return prev;
    }, {});
};


// 获取 search 参数
const getParams = (key = '') => {
    const params = queryParse(window.location.search);
    if (key === '') {
        return params;
    }
    return params[key];
};

// 重定向至订单列表，使其固定返回到小程序
const redirectToOrderList = (() => {
    const ua = navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
        window.wx.miniProgram.redirectTo({
            url: `/pages/webview?token=1&url=${encodeURIComponent(`${window.location.origin}/order/list`)}`
        });
    } else {
        window.location.href = `${window.location.origin}/order/list`;
    }
});

export {
    addUrlQuery,
    getCookie,
    setCookie,
    clearCookie,
    fixNum,
    isEmpty,
    generateId,
    getParams as search,
    queryParse,
    redirectToOrderList
};
